import React from 'react';

import { Field, FieldArray, useFormikContext } from 'formik';
import { FaTrashAlt } from 'react-icons/fa';
import { MdAdd, MdOutgoingMail, MdVisibility } from 'react-icons/md';

import { Box, Button, Divider, Flex, FormLabel, Grid, Select, Tooltip, useDisclosure } from '@chakra-ui/react';

import { CommonModal } from '../../../../components/CommonModal/CommonModal';
import { hexToRgba } from '../../../../utils/colors/hexToRgba';
import { renderContactFields } from '../Helpers/renderContactFields';
import { renderField } from '../Helpers/renderField';
import { renderSelectField } from '../Helpers/renderSelectField';

// Mapeamento de cores para cada status
const statusColors = {
  PUBLISHED: '#6BDD9E',
  ENVIADO: '#6BDD9E',
  EM_RASCUNHO: '#F1C40F',
  PENDENTE: '#F1C40F',
  CANCELLED: '#E74C3C',
  DEPRECATED: '#AFAEB4',
};

// Função para obter a cor de fundo com base no status
const getStatusColor = (status) => statusColors[status] || '#FFFFFF';

export const DynamicForm = ({ suppliersList = [], processList = [], showDocument, sendEmail, disabledButtons, type }) => {
  const { values, setFieldValue } = useFormikContext();
  const { isOpen: isDeleteModalOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();

  // Propriedades comuns para campos inativos
  const inactiveFieldProps = { readOnly: true, disabled: true };

  // Função para adicionar um novo fornecedor à lista
  const addSupplier = () => {
    const newSupplier = { documentNumberFormatted: '', name: '', contacts: [] };
    setFieldValue('suppliers', [...(values.suppliers || []), newSupplier]);
  };

  // Função para remover um fornecedor da lista pelo índice
  const removeSupplier = (index) => {
    setFieldValue(
      'suppliers',
      values.suppliers.filter((_, i) => i !== index)
    );
  };

  // Função para lidar com a mudança de CNPJ e atualizar os campos relacionados
  const handleCNPJChange = (event, supplierIndex) => {
    const selectedCNPJ = event.target.value;
    const selectedSupplier = suppliersList.find((supplier) => supplier.documentNumberFormatted === selectedCNPJ);

    setFieldValue(`suppliers[${supplierIndex}].documentNumberFormatted`, selectedCNPJ);
    setFieldValue(`suppliers[${supplierIndex}].name`, selectedSupplier?.name || '');
    setFieldValue(`suppliers[${supplierIndex}].address`, selectedSupplier?.address || '');
    setFieldValue(
      `suppliers[${supplierIndex}].contacts`,
      selectedSupplier?.contacts.map(({ name, email }) => ({ name, email })) || []
    );
  };

  // Renderiza os campos de cada fornecedor, incluindo controles de interação
  const renderSupplierFields = (supplier, index) => {
    const hasContacts = supplier.contacts.length > 0;
    const hasSupplier = supplier.documentNumberFormatted?.trim() !== '';

    return (
      <Grid key={index} borderWidth="1px" borderRadius="md" borderColor="#70707036" p={4} mb={4} position="relative">
        <Grid gridTemplateColumns="1fr 1fr" columnGap="40px">
          <Flex direction="column" mb={4}>
            <FormLabel>CNPJ do Fornecedor</FormLabel>
            <Field name={`suppliers[${index}].documentNumberFormatted`}>
              {({ field }) => (
                <Select {...field} placeholder="Selecione um CNPJ" onChange={(e) => handleCNPJChange(e, index)}>
                  {suppliersList.map((option) => (
                    <option key={option.identifier} value={option.documentNumberFormatted}>
                      {option.documentNumberFormatted}
                    </option>
                  ))}
                </Select>
              )}
            </Field>
          </Flex>
          {/* Campo de Nome do Fornecedor com propriedades inativas */}
          {renderField(`suppliers[${index}].name`, 'Nome do Fornecedor', inactiveFieldProps)}
        </Grid>
        {/* Campo de Endereço do Fornecedor com propriedades inativas */}
        {renderField(`suppliers[${index}].address`, 'Endereço', inactiveFieldProps)}

        <Flex justifyContent="space-between">
          {/* Botões para Visualizar Documento e Enviar E-mail, disponíveis apenas no modo de edição */}
          {type === 'edit' && (
            <Flex alignItems="center" gap={2}>
              {hasSupplier && (
                <Button variant="iconDashed" type="button" onClick={() => showDocument(index)}>
                  <Tooltip label="Visualizar" fontSize="md">
                    <Box>
                      <MdVisibility size={20} />
                    </Box>
                  </Tooltip>
                </Button>
              )}
              {hasContacts && (
                <Button
                  variant="iconDashed"
                  type="button"
                  onClick={() => sendEmail(index)}
                  isDisabled={disabledButtons.includes(index)}>
                  <Tooltip label="Enviar e-mail" fontSize="md">
                    <Box>
                      <MdOutgoingMail size={20} />
                    </Box>
                  </Tooltip>
                </Button>
              )}
            </Flex>
          )}
          {/* Botão para remover o fornecedor da lista */}
          <Flex alignItems="center" gap={2}>
            <Button variant="remove" type="button" onClick={openDeleteModal}>
              <Tooltip label="Remover fornecedor" fontSize="md">
                <Flex align="center" gap="10px" p="2px 8px" color="#ec7063" fontSize="14px">
                  <FaTrashAlt color="#ec7063" size={16} />
                </Flex>
              </Tooltip>
            </Button>
          </Flex>
          {/* Modal para confirmar remoção do fornecedor */}
          <CommonModal
            heading="Deseja mesmo remover esse fornecedor?"
            subtitle="Ele será removido dessa operação apenas."
            callback={() => removeSupplier(index)}
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            variant="caution"
            size="md"
            removeRadius
          />
        </Flex>

        {/* Divisor visual se houver contatos do fornecedor */}
        {hasContacts && <Divider m="20px 0" />}

        {/* Renderiza os campos de contato do fornecedor */}
        <FieldArray
          name={`suppliers[${index}].contacts`}
          render={() => renderContactFields(supplier.contacts, index, inactiveFieldProps)}
        />
      </Grid>
    );
  };

  // Estilos aplicados ao campo de status com base na cor do status
  const statusStyles = {
    color: `${getStatusColor(values.status)} !important`,
    border: `1px dashed ${getStatusColor(values.status)} !important`,
    backgroundColor: `${hexToRgba(getStatusColor(values.status), 0.2)} !important`,
    readOnly: true,
    maxWidth: 'max-content',
    maxHeight: '40px !important',
    textAlign: 'center',
    p: '12px !important',
  };

  return (
    <>
      {/* Renderiza o campo de status com estilos dinâmicos */}
      {renderField('status', 'Status', statusStyles)}
      <Grid gridTemplateColumns="1fr 1fr" gap="40px">
        {/* Campo de seleção de processo */}
        {renderSelectField({
          name: 'process',
          placeholder: 'Selecione o Processo',
          list: processList,
          keyProp: 'identifier',
          displayProp: 'code',
        })}
        {/* Campo de data de contestação */}
        {renderField('date', 'Data de Contestação', { type: 'date' })}
      </Grid>

      {/* Renderiza o array de fornecedores e o botão para adicionar novos fornecedores */}
      <FieldArray
        name="suppliers"
        render={() => (
          <>
            {values.suppliers.map((supplier, index) => renderSupplierFields(supplier, index))}
            <Button variant="add" type="button" onClick={addSupplier}>
              <MdAdd /> Adicionar Fornecedor
            </Button>
          </>
        )}
      />
    </>
  );
};
