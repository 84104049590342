import React from 'react';

import { Field } from 'formik';

import { Flex, FormLabel, Grid, Input, Select, Textarea } from '@chakra-ui/react';

export const DynamicForm = () => {
  return (
    <>
      <Grid gridTemplateColumns={'1fr 1fr'} gap={'40px'}>
        <Flex direction="column" mb={4}>
          <FormLabel>Tipo</FormLabel>
          <Field name="typeSystem">
            {({ field }) => (
              <Select {...field} placeholder="Selecione o tipo">
                <option value="SYSTEM_ANALYTICS">Painel Analytics</option>
                <option value="APP_DRIVER">App do Motorista</option>
              </Select>
            )}
          </Field>
        </Flex>

        <Flex direction="column" mb={4}>
          <FormLabel>Modalidade</FormLabel>
          <Field name="modality">
            {({ field }) => (
              <Select {...field} placeholder="Selecione uma modalidade">
                <option value="AUTHENTICATED">Autenticado</option>
                <option value="ANONYMOUS">Público</option>
              </Select>
            )}
          </Field>
        </Flex>
      </Grid>
      <Flex direction="column" mb={4}>
        <FormLabel>Título</FormLabel>
        <Field name="title">{({ field }) => <Input {...field} />}</Field>
      </Flex>

      <Flex direction="column" mb={4}>
        <FormLabel>Conteúdo</FormLabel>
        <Field name="description">
          {({ field }) => <Textarea {...field} minH="230px" resize="none" bgColor="#F2F2F2" borderColor="#7070703B" />}
        </Field>
      </Flex>
    </>
  );
};
