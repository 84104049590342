import { useEffect, useRef } from 'react';

import { useLoadScript } from '@react-google-maps/api';

import { renderField } from './renderField';

export const renderAddressAutocomplete = (name, placeholder, setFieldValue) => {
  const inputRef = useRef(null);

  // Carregar o script do Google Maps API
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries: ['places'],
  });

  // Configurar o Autocomplete quando o script estiver carregado
  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'br' }, // Restringe para endereços no Brasil, altere conforme necessário
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setFieldValue('address', place.formatted_address);
        }
      });
    }
  }, [isLoaded, setFieldValue]);

  return <>{renderField(name, placeholder, { ref: inputRef })}</>;
};
