import React from 'react';

import { useFormikContext } from 'formik';

import { Grid } from '@chakra-ui/react';

import { renderField } from '../../../../components/CommonForm/Fields/renderField';
import formatDocument from '../../../../utils/fields/masks/formatDocument';

export const DynamicForm = () => {
  const { values, setFieldValue } = useFormikContext();

  // Função para lidar com a mudança de CNPJ e atualizar os campos relacionados
  const handleChange = (event) => {
    const selectedCNPJ = formatDocument(event.target.value);
    setFieldValue('cnpj', selectedCNPJ); // Passa o nome do campo e o valor formatado diretamente
  };

  return (
    <Grid borderWidth="1px" borderRadius="md" borderColor={'#70707036'} p={4} mb={4}>
      <Grid gridTemplateColumns="1fr 1fr" columnGap="40px">
        {renderField('cnpj', 'CNPJ', {
          maxLength: 18,
          onChange: handleChange, // Chama handleChange diretamente
        })}
      </Grid>
    </Grid>
  );
};
