import { Grid } from '@chakra-ui/react';

import { renderField } from './renderField';

export const renderContactFields = (contacts, supplierIndex, props) =>
  contacts.map((contact, contactIndex) => (
    <Grid key={contactIndex} gridTemplateColumns="1fr 1fr" columnGap="40px">
      {renderField(`suppliers[${supplierIndex}].contacts[${contactIndex}].name`, 'Nome', { ...props })}
      {renderField(`suppliers[${supplierIndex}].contacts[${contactIndex}].email`, 'E-mail', { ...props })}
    </Grid>
  ));
