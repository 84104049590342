import React from 'react';

import { Avatar, Divider, Flex, Image, Text, Tooltip } from '@chakra-ui/react';

import Card from '../../../../components/Card/Card';
import { processCheck, processUncheck } from '../../../../utils/exports';
import { capitalizeWords } from '../../../../utils/text/format/capitalizeWords';

const DriverInfo = ({ entity, viewport }) => {
  const calcCarbon = entity?.carbonTrack?.calculated + entity?.carbonTrack?.retired;
  const calcCarbonFormatted = calcCarbon?.toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 });

  const checkPlate = () => {
    if (entity?.plates) {
      return entity?.plates;
    } else if (entity?.vehicle?.licensePlate) {
      return entity?.vehicle?.licensePlate;
    } else {
      return 'Não informado';
    }
  };

  const plates = checkPlate();

  return (
    <Card
      w="full"
      p="20px"
      gap={viewport > '700' ? '40px' : '20px'}
      direction={viewport > '700' ? 'row' : 'column'}
      fontSize={{ sm: '18px', md: '20px' }}>
      <Flex direction="column" gap="20px">
        <Flex align="center" gap="20px">
          <Avatar
            src={`${process.env.REACT_APP_API_URL}${
              entity?.userDriverPicture && entity?.userDriverPicture.replace('//profile', '/profile')
            }`}
            h="80px"
            w="80px"
            borderRadius="6px"
          />

          <Flex h="full" direction="column" textColor="#422C76" justify="space-between">
            <Text fontWeight="bold" fontSize={{ sm: '20px', md: '24px' }}>
              {entity?.userDriverName ? capitalizeWords(entity.userDriverName) : 'Nome não informado'}
            </Text>

            <Text fontSize={{ sm: '16px', md: '18px' }}>
              {entity?.userDriverTrips} {entity?.userDriverTrips < 1 ? 'Nenhuma viagem realizada' : 'Viagens realizadas'}
            </Text>
          </Flex>
        </Flex>

        <Flex gap="20px">
          <Flex direction="column">
            <Text fontSize={{ sm: '16px', md: '18px' }}>Veículo</Text>

            <Text fontWeight="bold">{plates}</Text>
          </Flex>

          <Flex direction="column">
            <Text fontSize={{ sm: '16px', md: '18px' }}>Transportadora</Text>

            <Text fontWeight="bold">{entity?.shippingCompanyName}</Text>
          </Flex>
        </Flex>
      </Flex>

      {viewport > '700' && <Divider orientation="vertical" borderLeftWidth="2px" mx="10px" />}

      <Flex
        direction={viewport > '700' ? 'column' : 'row'}
        gap="20px"
        align="flex-start"
        justify={viewport > '700' ? 'space-between' : 'normal'}>
        <Flex direction="column">
          <Text fontSize={{ sm: '16px', md: '18px' }}>ID Viagem</Text>

          <Text fontWeight="bold">#{entity?.code}</Text>
        </Flex>

        <Flex direction="column">
          <Text fontSize={{ sm: '16px', md: '18px' }}>Pegada de carbono:</Text>

          <Tooltip label={entity?.carbonTrack?.retired > 0 ? 'Compensado' : 'Não compensado'}>
            <Flex
              w="max-content"
              p="5px 10px"
              border="1px"
              borderColor="#2ECC71"
              color="#2ECC71"
              align="center"
              borderRadius="10px"
              gap="5px"
              fontSize={{ sm: '14px', md: '16px' }}>
              Pegada CO₂: {calcCarbonFormatted}t
              {entity?.carbonTrack?.retired > 0 ? (
                <Image src={processCheck} opacity={'1'} />
              ) : (
                <Image src={processUncheck} opacity={'1'} />
              )}
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
    </Card>
  );
};

export default DriverInfo;
