import React, { useEffect, useRef, useState } from 'react';

import { MdAdd } from 'react-icons/md';

import { Button, Text, useDisclosure } from '@chakra-ui/react';

import credit from '../../../assets/images/credit-analysis.svg';
import { CommonDrawer } from '../../../components/CommonDrawer/CommonDrawer';
import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import validateCNPJ from '../../../utils/fields/validadeCNPJ';
import { filterOptionsCreditAnalysis } from '../../../utils/filters/filterPresets';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';
import { DynamicForm } from './components/DynamicForm';

const yup = require('yup');

// Constantes para mensagens de sucesso e erro
const MESSAGES = {
  cnpjSuccess: 'Análise de crédito cadastrada com sucesso!',
  cnpjError: 'A operação falhou, tente novamente em breve',
};

const CreditAnalysisPage = () => {
  const hasPermission = permissions.vendemmiaCreditAnalysis;

  const request = useRef(0);

  const [isMobile, setIsMobile] = useState(false);

  const [filterOptions, setFilterOptions] = useState([]);

  const [list, setList] = useState([]);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //trigger Drawer
  const { isOpen: drawerIsOpen, onOpen: drawerOnOpen, onClose: drawerOnClose } = useDisclosure();

  //component preset
  const subtitle = `Existe um total de ${metadata.total_count} análises`;

  const load = (filters, key) => {
    setIsLoading(true);

    requests
      .listCreditAnalysis(filters, metadata.current_page > 0 ? metadata.current_page : 1)
      .then((data) => {
        if (request.current && request.current > key) {
          return;
        }

        request.current = key;
        setList(data.data);
        setMetadata(data.meta);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const getFilterOptions = async () => {
    setFilterOptions(filterOptionsCreditAnalysis);
  };

  const initialValues = {
    cnpj: '', // Inicializar como string vazia
  };

  const validationSchema = yup.object().shape({
    cnpj: yup
      .string()
      .required('Campo obrigatório')
      .test('cpf-validate', 'CNPJ inválido', (value) => {
        // Se o campo estiver vazio, não execute a validação
        if (!value) {
          return true;
        }
        return validateCNPJ(value);
      }),
  });

  const onSubmitForm = async (values) => {
    await executeRequest({
      action: () =>
        requests.addCreditAnalysis({
          documentNumber: values.cnpj,
        }),
      msgSuccess: MESSAGES.cnpjSuccess,
      msgError: MESSAGES.cnpjError,
      setIsLoading,
      triggerRefresh,
      callback: drawerOnClose,
    });
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <Page
      screen="credit"
      title="Análise de Crédito"
      breadcrumbIcon={credit}
      breadcrumbs={[{ link: '#', title: 'Vendemmia' }]}
      textFilterPlaceholder="CNPJ, Nome Fantasia, Razão Social"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      filterOptions={filterOptions}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar análise de crédito',
          text: 'Cadastro de análise de crédito',
          action: (
            <Button
              bgColor="green"
              color="white"
              pt="23px"
              pb="23px"
              borderRadius="50px"
              _hover={{ bgColor: '#70D499' }}
              onClick={() => {
                drawerOnOpen();
              }}>
              <MdAdd color="#FFFFFF" size={20} />
              <Text textStyle="paragraph" ml="10px">
                Nova análise
              </Text>
            </Button>
          ),
          modality: 'custom',
        },
      ]}>
      <CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />
      <CommonDrawer
        heading={'Novo Cadastro'}
        isOpen={drawerIsOpen}
        onOpen={drawerOnOpen}
        isLoading={isLoading}
        loadingText={'Salvando'}
        buttonName={'Consultar'}
        hasForm={true}
        formik={{ initialValues, validationSchema }}
        onClose={() => {
          drawerOnClose();
        }}
        callback={onSubmitForm}>
        <DynamicForm />
      </CommonDrawer>
    </Page>
  );
};

export default CreditAnalysisPage;
