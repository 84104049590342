import React, { useState } from 'react';

import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Box, Flex, Icon, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import DefaultModal from '../../../../components/Modal/Template/DefaultModal';
import Paginate from '../../../../components/Paginate/Paginate';
import requests from '../../../../services/requests';
import ModalEditVehicle from '../../VehicleManagement/components/ModalEditVehicle';
import ModalChangeToggle from '../Modals/ModalChangeToggle';

const VehiclesTable = ({ list, loadList, action, setAction, metadata, setMetadata, hideButtons = false }) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);

  const handleDelete = (identifier) => {
    setIsLoadingDelete(true);

    requests
      .deleteVehicle(identifier)
      .then((response) => {
        setIsLoadingDelete(false);
        toast.success(`Veículo ${response?.deleted ? 'excluído' : 'inativado'} com sucesso!`);
        loadList();
      })
      .catch(() => {
        setIsLoadingDelete(false);
        toast.error('Problemas ao remover veículo, tente novamente ou entre em contato com o Administrador');
      });
  };

  const handleClick = (key, identifier, statusText) => {
    setIsLoadingChangeStatus(true);
    let status = 'activate';

    if (statusText == 'ATIVO') {
      status = 'inactivate';
    }

    requests
      .changeStatusVehicle(identifier, status)
      .then((response) => {
        toast.success(`Veículo ${response?.deleted ? 'excluído' : 'inativado'} com sucesso!`);
        loadList();
        setIsLoadingChangeStatus(false);
      })
      .catch(() => {
        setIsLoadingChangeStatus(false);
        toast.error('Problemas ao alterar status do veículo, tente novamente ou entre em contato com o Administrador');
      });
  };

  return (
    <>
      <Table className="table-default">
        <Thead border="1px" borderBottom="1px">
          <Tr>
            <Th>Placa</Th>
            <Th>Modelo</Th>
            <Th>Cor</Th>
            <Th>Ano fab.</Th>
            <Th>Tipo carreta</Th>
            <Th>Capacidade</Th>
            <Th>
              Veículo
              <br /> próprio
            </Th>
            {!hideButtons && (
              <>
                <Th>Ações</Th>
                <Th>Status</Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody border="1px">
          {list?.map((entity, key) => (
            <>
              <Tr key={key} style={{ '--delay': `0.${key}s` }}>
                <Td>{entity.plate ?? '-'}</Td>
                <Td>{entity.model ?? '-'}</Td>
                <Td>{entity.color ?? '-'}</Td>
                <Td>{entity.year ?? '-'}</Td>
                <Td>{entity.type ?? '-'}</Td>
                <Td>{entity.capacity ?? '-'}</Td>
                <Td>{entity.ownershipLabel ?? '-'}</Td>
                {!hideButtons && (
                  <>
                    <Td>
                      <Flex align="center">
                        <ModalEditVehicle vehicle={entity} action={action} setAction={setAction} loadList={loadList} />

                        <DefaultModal
                          ctaButton={
                            <Box h="24px">
                              <Icon
                                as={MdDeleteOutline}
                                color="#AFAEB4"
                                w="24px"
                                h="24px"
                                cursor="pointer"
                                _hover={{ color: '#E74C3C' }}
                              />
                            </Box>
                          }
                          title={'Excluir o veículo'}
                          loading={isLoadingDelete}
                          subtitle={
                            <>
                              Você tem certeza que deseja excluir esse veículo?
                              <br /> Esse veículo ficará indisponível para viagens.
                            </>
                          }
                          buttonColor="red"
                          txtButton="Excluir"
                          callback={(decision) => {
                            if (decision) {
                              handleDelete(entity.identifier);
                            }
                          }}
                        />
                      </Flex>
                    </Td>
                    <Td>
                      <ModalChangeToggle
                        text=" esse veículo"
                        status={entity.status}
                        callback={(decision) => {
                          if (decision) {
                            handleClick(key, entity.identifier, entity.statusText);
                          }
                        }}
                      />
                    </Td>
                  </>
                )}
              </Tr>
            </>
          ))}

          {list?.length == 0 && (
            <Tr>
              <Td colSpan={9}>
                <Flex justify="center" my="25px">
                  Nenhum dado encontrado para os parâmetros filtrados.
                </Flex>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {metadata.total_pages > 1 && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </>
  );
};

export default VehiclesTable;
