/**
 *
 * Template	padrão de tabela dinamica do sistema.
 *
 * @param  {String} title titulo do componente (opcional - desejável)
 * @param  {String} subtitle subtitle do componente (opcional)
 * @param  {Object[]} categories categorias da tabela e campos a serem populados, 
 * os campos podem ser ordenados pela ordem desejada(só mudar a posição no objeto), somente os campos escolhidos seram exibidos no processo.
 * 
    * @param {categories} title: é o nome da coluna que será exibido na lista
    * @param {categories} field: é o valor que ativa o filtro da categoria escolhida, exemplo: e.code(o 'e' é padrão para a filtragem)
    * @param {categories} value: deve se usar o valor do campo que vem da API para vincular a categoria criada
    * @param {categories} defaultValue: é o valor padrão de exibição quando a API retorna null/undefined, será exibido o que for adicionado como string, 
    * tambem pode ser usado com valor vazio para casos como o do "Ações" não exibe nenhuma informação na coluna.
    * @param {categories} link: adicionamos link ao campo desejado, o endereço será o endpoint escolhido(pode conter identifier), exemplo: '/comex/process/detail/${identifier}'
    * @param {categories} align: determina o alinhamento do item especifico da tabela.
    * @param {categories} tooltip: Aplica tooltip com descrição na categoria específica. 
 * 
 * @param  {Object[]} subCategories subcategorias da tabela, utiliza os mesmos conceitos de categories.
 * @param  {String} mainCategoryMobile categoria principal que será exibido apenas no mobile (opcional, exemplo: 'codeReadable')
 * exemplo: [{ category: 'NCM', describe: 'identificador de NCM' }]
 * @param  {Bool} hasCollapsed se a tabela deverá possuir um subnivel (opcional)
 * @param  {Bool} hasPagination se a tabela haverá paginação (opcional, incluir outras propriedades para o funcionamento)
 * @param  {Bool} hasDetailsButton se deverá ter um botão nos itens da lista para a pagina de edição/exibição (opcional)
 * @param  {Bool} hasDetailsButtonSublist se deverá ter um botão nos itens da sublista para a pagina de edição/exibição (opcional)
 * @param  {String} detailsRedirectEndpoint endpoint para o funcionamento de hasDetailsButton (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  {String} detailsRedirectEndpointSublist endpoint para o funcionamento de hasDetailsButtonSublist (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  {Bool} hasDeleteButton se deverá ter um botão nos itens da lista para a remoção de itens (opcional, incluir deleteButtonAction para o funcionamento)
 * @param  {Function} deleteButtonAction necessário para o funcionamento de hasDeleteButton determina a ação do botão (opcional)
 * @param  {Object[]} customButtons gerar lista de botões personalizados (opcional, 
 * exemplo: [{ label: 'Editar', icon: <MdOpenInNew size={20} />, action: handleCustomButtonAction }])
 *
 * @param  {Object[]} customFields gerar lista de campos personalizados (opcional, 
 * 
    * @param {customFields} customFieldstype: 'select | radio'
    * @param {customFields} name: é o nome da chave que voce deseja receber da API para popular o value dos inputs
    * @param {customFields} action: é a ação que será realizada apos o clique no input(retorna dois valores para o action: identifier e currentFilter)
    *
    * @param {customFields} options: é a lista de opções para o select(deve conter os valores padrões: label, value, slug) | é apenas para "select"
    * @param {customFields} roles: são as regras para determinar se será true ou false um valor da chave escolhida em name, exemplo: { label: 'Ativado', condition: 'Active', status: true } | é apenas para "radio"
    * @param {customFields} bgColor: determina a cor global do background ativo(turnIn) e inativo(turnOff) do input radio, exemplo: bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' }
 
* exemplo: [{ type: 'select', options: roleOptions, name: 'role', action: handleCustomUserAction }],
    {
      type: 'radio',
      title: 'Status',
      name: 'statusReadable',
      roles: [
        { label: 'Ativado', condition: 'Ativo', status: true },
        { label: 'Desativado', condition: 'Inativo', status: false },
      ],
      bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' },
      action: handleCustomUserAction,
    },
 * 
 *
 * @param  {String} collapsedContentType template da sublista, (opcional, tipos: "table" || "timeline")
 * @param  {Icon} collapsedIcon icone personalizado para o colapso da tabela (opcional)
 * @param  {Array} tags necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), 
 * exemplo: ['status', 'situacao']
 * @param  {Object[]} statusPreset necessário para o funcionamento das tags (opcional), 
 * exemplo: statusPreset: [{
    // ... definições de cores para tags
     @param {statusPreset} color: define a cor da tag 
     @param {statusPreset} label: define o nome que sera exibido na tag, pode ser qualquer nome
     @param {statusPreset} value: deve ser definido o valor que vem do campo da API, precisa ser exatamente igual para o funcionamento
    },
  ]
 * @param  {Object[]} rawData dados brutos da API (obrigatório para o funcionamento de customFields)
 * @param  {Object[]} list envio da lista a ser exibida (obrigatório)
 * @param  {Object[]} subList renderizar a lista secundária (opcional)
 * @param  {String} pathSublist indica qual será o caminho para puxar a lista de exibição da subList (opcional), exemplo: 'notes' = data.notes
 * @param  {String} emptyListMessage exibe uma mensagem personalizada para a lista vazia.
 * @param  {Number} maxLengthResume Limita a quantidade de caracteres para permitir a inclusão do resumo no texto.
 * @param  {String} cellRowSize determina a largura das cedulas de cada coluna(cabeçalho/conteudo) com a mesma proporção escolhida.
 * @param  {String} resizeRows determina a largura das cedulas de cada coluna de forma personalizada para o primeiro nivel da lista.
 * @param  {String} resizeRowsChildren determina a largura das cedulas de cada coluna de forma personalizada para o segundo nivel da lista(colapso).
 *
 * @param  {State} action ação da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {State} sorting ordenação da lista pelo cabeçalho | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  {State} metadata metadados da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {SetState} setAction atualização da ação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {SetState} setSorting atualização da ordenação | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  {SetState} setMetadata atualização da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {Function} handleSort metodo que determina a atualização da paginação com o filtro escolhido | necessário para o funcionamento de hasPagination (opcional)
 *
 */

import api from '../../../../assets/svgs/warehouse/api.svg';
import ftp from '../../../../assets/svgs/warehouse/ftp.svg';

//setup
export const commonListConfig = {
  title: '',
  // Estrutura da tabela
  categories: [
    {
      title: 'Importação',
      /* field: 'e.originModality', */
      value: 'originImport',
      align: 'center',
    },
    {
      title: 'ID NF (Sênior)',
      field: 'e.idNotafiscal',
      value: 'idNotafiscal',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Status',
      field: 'e.statusNf',
      value: 'statusNf',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Usuário',
      field: 'e.usuarioConferencia',
      value: 'usuarioConferencia',
      align: 'center',
    },
    {
      title: 'Armazém',
      field: 'e.ufArmazem',
      value: 'stateCodeFormatted',
      align: 'center',
    },
    {
      title: 'Nota fiscal',
      field: 'e.nuNota',
      value: 'nuNota',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Série',
      field: 'e.serieNf',
      value: 'serieNf',
      align: 'center',
    },
    {
      title: 'Pedido',
      field: 'e.idPedido',
      value: 'idPedido',
      defaultValue: 'não encontrado',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Canal',
      field: 'e.classTpPedido',
      value: 'classTpPedido',
      align: 'center',
    },
    {
      title: 'Destinatário',
      field: 'e.nomeEntrega',
      value: 'nomeEntrega',
    },
    {
      title: 'UF Destinatário',
      field: 'e.ufDestinatario',
      value: 'ufDestinatario',
      align: 'center',
    },
    {
      title: 'Cidade destinatário',
      field: 'e.recipientCity',
      value: 'recipientCity',
      align: 'center',
    },
    {
      title: 'Depositante',
      field: 'e.nomeDepositante',
      value: 'nomeDepositante',
    },
    {
      title: 'Transportadora',
      field: 'e.nomeTransportadora',
      value: 'nomeTransportadora',
    },
    {
      title: 'Data de cadastro',
      field: 'e.dtCadastro',
      value: 'dtCadastroFormatted',
      /* formatted: (value) => moment(value, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm'), */
      align: 'center',
    },
    {
      title: 'Início da separação',
      field: 'e.dtInicioSeparacao',
      value: 'dtInicioSeparacaoFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Término da separação',
      field: 'e.dtFimSeparacao',
      value: 'dtFimSeparacaoFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Início da conferência',
      field: 'e.dtInicioConferencia',
      value: 'dtInicioConferenciaFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Término da conferência',
      field: 'e.dtFimConferencia',
      value: 'dtFimConferenciaFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Volumes',
      field: 'e.qtVolumes',
      value: 'qtVolumes',
      align: 'center',
    },
    {
      title: 'Cub.',
      field: 'e.cubagemM3',
      value: 'cubagemM3Formatted',
      align: 'center',
    },
    {
      title: 'Valor total da NF',
      field: 'e.valorNf',
      value: 'valorNfFormatted',
      align: 'center',
    },
    {
      title: 'Peso bruto',
      field: 'e.weight',
      value: 'weightFormatted',
      align: 'center',
    },
    {
      title: 'Observação',
      field: 'e.observacao',
      value: 'observacao',
    },
    {
      title: 'Última atualização',
      field: 'e.updatedAt',
      value: 'updatedAtFormatted',
      /* formatted: (value) => moment(value).format('DD-MM-YYYY'), */
      align: 'center',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  subCategories: [
    {
      title: 'Código',
      value: 'codProduto',
      type: 'label',
      defaultValue: 'não possui',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'descricaoProduto',
    },
    {
      title: 'Quantidade',
      value: 'qtdeExpedidaFormatted',
      align: 'center',
    },
    {
      title: 'Valor',
      value: 'totalValueFormatted',
      align: 'center',
    },
    {
      title: 'Indústria',
      value: 'loteIndustrial',
      align: 'center',
    },
    {
      title: 'Número de série',
      value: 'infoEspecifica',
      align: 'center',
    },
    {
      title: 'Curva ABC',
      value: 'inAbc',
      align: 'center',
    },
  ],
  mainCategoryMobile: 'idNotafiscal',
  // Funcionalidades
  hasCollapsed: true,
  hasPagination: true,
  collapsedContentType: 'table',
  tags: ['statusNf', 'inAbc'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#422C76', label: 'Importado', value: 'IMPORTADO' },
    { color: '#422C76', label: 'Ag. Formação de romaneio/onda', value: 'AG. FORMAÇÃO DE ROMANEIO/ONDA' },
    { color: '#422C76', label: 'Ag. Separação', value: 'AG. SEPARAÇÃO' },
    { color: '#2EC5CC', label: 'Separação iniciada', value: 'SEPARAÇÃO INICIADA' },
    { color: '#2EA0CC', label: 'Separação concluída', value: 'SEPARAÇÃO CONCLUÍDA' },
    { color: '#CCA42E', label: 'Conferência concluída', value: 'CONFERÊNCIA CONCLUÍDA' },
    { color: '#CCA42E', label: 'Enviado para faturamento', value: 'ENVIADO PARA FATURAMENTO' },
    { color: '#9B2ECC', label: 'Faturado', value: 'FATURADO' },
    { color: '#CC2E96', label: 'Conferência iniciada', value: 'CONFERÊNCIA INICIADA' },
    { color: '#CC2E96', label: 'Coleta iniciada', value: 'COLETA INICIADA' },
    { color: '#E74C3C', label: 'Cancelado', value: 'CANCELADO' },
    { color: '#707070', label: 'Digitado', value: 'DIGITADO' },
    { color: '#CCA42E', label: 'Quarentena', value: 'QUARENTENA' },
    { color: '#422ECC', label: 'Processado', value: 'PROCESSADO' },
    { color: '#2ECC71', label: 'A', value: 'A' },
    { color: '#F1C40F', label: 'B', value: 'B' },
    { color: '#E74C3C', label: 'C', value: 'C' },
  ],
  images: ['originImport'],
  imagePreset: [
    { value: 'API', label: 'Importação via API Sênior', image: api },
    { value: 'FTP', label: 'Importação via FTP Sênior', image: ftp },
    { value: 'OUTROS', label: 'Importação via FTP Sênior', image: ftp },
  ],
  pathSublist: 'itens',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 18,
  maxLengthResumeChildren: 80,
  /* hasDeleteButton: true, */
  // Estilo
  resizeRows:
    '100px 120px 180px 100px 120px 120px 80px 135px 120px 250px 100px 250px 250px 250px 180px 180px 180px 180px 180px 100px 120px 110px 120px 220px 180px 80px',
  resizeRowsChildren: '180px 500px 120px 120px 120px 120px 120px',
};
