export const buttonStyles = {
  variants: {
    primary: {
      height: '40px',
      bg: 'primary',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { bg: 'violet2' },
      _focus: { bg: 'primary', boxShadow: 'none' },
    },

    secundary: {
      height: '40px',
      bg: 'transparent',
      color: 'linkColor',
      border: '1px',
      fontSize: '16px',
      borderColor: 'linkColor',
      borderRadius: '9px',
      _hover: { bg: 'lightViolet', opacity: '1' },
      _focus: { color: 'primary', border: '2px', boxShadow: 'none' },
    },

    panelTabsButton: {
      bg: '#F2E9FFAB',
      color: 'linkColor',
      fontSize: '16px',
      borderColor: 'linkColor',
      _hover: { bg: 'lightViolet', opacity: '1' },
      _focus: { color: 'primary', boxShadow: 'none' },
    },

    floatingGreenButton: {
      p: '1.5rem 2rem 1.5rem 2.3rem',
      borderRadius: '50px',
      bg: 'green',
    },

    reset: {
      h: 'initial',
      bg: 'transparent',
      border: 'none',
      display: 'grid',
      alignItems: 'initial',
      justifyContent: 'initial',
      fontWeight: '400',
      w: 'full',
      p: 'initial',
    },

    commonTable: {
      bg: 'bgActionButton',
      color: 'linkColor',
      width: 'initial',
      h: 'initial',
      bg: 'transparent',
      border: 'none',
      display: 'grid',
      alignItems: 'initial',
      justifyContent: 'initial',
      fontWeight: '400',
      w: 'initial',
      p: 'initial',
    },

    caution: {
      height: '40px',
      bg: 'red',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { bg: 'lightRed' },
      _focus: { bg: 'red', boxShadow: 'none' },
    },

    clear: {
      height: '40px',
      bg: 'green',
      color: 'white',
      border: 'none',
      fontSize: '16px',
      borderRadius: '9px',
      _hover: { opacity: '0.8' },
      _focus: { bg: 'green', boxShadow: 'none' },
    },

    add: {
      height: '40px',
      bg: '#DDF7E8',
      color: '#14A752',
      border: '1px dashed #85E1AB',
      fontSize: '16px',
      borderRadius: '8px',
      fontWeight: 'medium',
      gap: '10px',
      /* boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)', */
      _hover: { opacity: '0.8' },
      _focus: { bg: '#DDF7E8', boxShadow: 'none' },
    },

    icon: {
      display: 'grid',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '0.375rem',
      height: '36px',
      maxWidth: '36px',
      bg: 'transparent',
      color: '#6C48C2',
      border: '1px solid #70707033',
      fontSize: '16px',
      fontWeight: 'medium',
      gap: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
      _hover: { bg: '#D0B0FD36' },
      /* _focus: { bg: '#D0B0FD36', boxShadow: 'none' }, */
    },

    iconDashed: {
      height: '40px',
      bg: '#D0B0FD36',
      color: '#6C48C2',
      border: '1px dashed #b286e0',
      fontSize: '16px',
      borderRadius: '8px',
      fontWeight: 'medium',
      gap: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
      _hover: { opacity: '0.8' },
      _focus: { bg: '#D0B0FD36', boxShadow: 'none' },
    },

    remove: {
      /* position: 'absolute',
      right: '15px',
      top: '8px', */
      height: '40px',
      minW: '1.5rem',
      bg: 'rgba(236, 112, 99,0.1)',
      border: '1px dashed #ec7063',
      fontSize: '16px',
      borderRadius: '8px',
      fontWeight: 'medium',
      gap: '10px',
      padding: '6px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
      _hover: { opacity: '0.8' },
      /* _focus: { bg: '#D0B0FD36', boxShadow: 'none' }, */
    },

    submit: {
      height: '40px',
      bg: '#6C48C2',
      color: '#ffffff',
      fontSize: '16px',
      p: '8px 35px',
      borderRadius: '8px',
      fontWeight: 'medium',
      gap: '10px',
      _hover: { opacity: '0.8' },
      _focus: { bg: '#DDF7E8', boxShadow: 'none' },
    },
  },
};
