import { Field } from 'formik';

import { Flex, FormLabel, Select } from '@chakra-ui/react';

/**
 * Renderiza um campo de seleção (Select) dinâmico para formulários com Formik.
 *
 * @param {Object} options - Opções para renderização do campo.
 * @param {string} options.name - O nome do campo.
 * @param {string} options.placeholder - Placeholder para o campo.
 * @param {Array}  options.list - Lista de opções para o select.
 * @param {string} options.keyProp - A chave que será usada como a chave única (key) para cada opção.
 * @param {string} options.displayProp - A chave que será exibida como o valor da opção.
 * @param {Object} options.props - Outras propriedades que podem ser passadas para o campo Select.
 */
export const renderSelectField = ({ name, placeholder, list = [], keyProp, displayProp, ...props }) => (
  <Flex direction="column" mb={4}>
    <FormLabel>{placeholder}</FormLabel>
    <Field name={name}>
      {({ field }) => (
        <Select {...field} placeholder={placeholder} {...props} /* value={'ASK0588/24'} */>
          {list.map((option) => (
            <option key={option[keyProp]} value={option[displayProp]}>
              {option[displayProp]}
            </option>
          ))}
        </Select>
      )}
    </Field>
  </Flex>
);
