import { useState } from 'react';

import { MdSearchOff } from 'react-icons/md';

import { Box, Button, Flex, Image, Link, Text, Tooltip } from '@chakra-ui/react';

import { hexToRgba } from '../../../utils/colors/hexToRgba';
import { replaceIdentifier } from '../../../utils/replace/replaceIdentifier';
import ShortenText from '../../../utils/text/format/shortenText';
import { isArrayOrArrayOfObjects } from '../Helpers/isArrayOrArrayOfObjects';

export const CustomValueRenderer = ({
  property,
  value,
  index,
  linkValue,
  category,
  formattedValue,
  identifier,
  align,
  type,
  variantTag,
  renderHTML,
  images,
  imagePreset,
  tags,
  statusPreset,
  viewport,
  maxLengthResume,
  rawItem,
  showMore,
}) => {
  const [showExtraValues, setShowExtraValues] = useState(false);

  // Alterna a visualização entre mostrar mais ou menos valores
  const toggleShowMore = () => setShowExtraValues(!showExtraValues);

  // Função para renderizar links
  const renderLink = (linkValue, content) => (
    <Link color="linkColor" href={replaceIdentifier(linkValue, identifier)} target="_blank" rel="noopener noreferrer">
      {content}
    </Link>
  );

  // Função para renderizar imagens
  const renderImage = (value, align) => {
    const preset = imagePreset.find((item) => item.value === value);
    return preset ? (
      <Tooltip label={preset.label}>
        <Text margin={align ?? '0 auto'}>
          <Image maxH={35} minW={35} src={preset.image} />
        </Text>
      </Tooltip>
    ) : (
      <Text margin={align ?? '0 auto'}>
        <Image maxH={35} minW={35} src={value} />
      </Text>
    );
  };

  // Função para renderizar status
  const renderStatus = (property, value, index) => {
    const status = statusPreset.find((item) => item.value === value);
    return status ? (
      <>
        {variantTag === 'solidFlat' && (
          <Text key={`${property}-${index}-status`} bg={status.color} data-status-tag>
            {viewport < 1500 ? (
              <Tooltip label={status.label}>
                <Box>{status.label}</Box>
              </Tooltip>
            ) : (
              status.label
            )}
          </Text>
        )}

        {variantTag === 'softDashed' && (
          <Text
            key={`${property}-${index}-status`}
            color={`${status.color} !important`}
            border={`1px dashed ${status.color}`}
            bg={hexToRgba(status.color, 0.2)}
            textTransform={'uppercase'}
            data-status-tag>
            {viewport < 1500 ? (
              <Tooltip label={status.label}>
                <Box>{status.label}</Box>
              </Tooltip>
            ) : (
              status.label
            )}
          </Text>
        )}
      </>
    ) : null;
  };

  // Função para renderizar conteúdo HTML
  const renderHTMLContent = (htmlContent) => {
    return htmlContent ? <Text dangerouslySetInnerHTML={{ __html: htmlContent }} /> : <Text>No HTML content</Text>;
  };

  // Adaptação da lógica para mostrar "+X" valores extras
  const renderFormattedValueWithShowMore = (formattedValue) => {
    const valuesArray = formattedValue.split(',');

    const styles = {
      border: '1px solid #6C48C2',
      padding: '0px 0.75rem',
      borderRadius: '7px',
      color: '#6C48C2',
      fontSize: '14px',
    };

    if (valuesArray.length > 1 && showMore) {
      return (
        <Text data-type="label" display={'flex'} minW={'150px'} gap={'15px'} justifyContent={'flex-end'} textAlign={'right'}>
          <Flex>
            <Text data-type="label" {...styles}>
              {valuesArray[0]}
              {showExtraValues && (
                <>
                  {', '} {valuesArray.slice(1).join(', ')}
                </>
              )}
            </Text>
          </Flex>
          <Button
            variant="link"
            fontWeight={'bold'}
            onClick={(e) => {
              e.stopPropagation();
              toggleShowMore();
            }}>
            {!showExtraValues ? <>+{valuesArray.length - 1}</> : <MdSearchOff size={20} />}
          </Button>
        </Text>
      );
    }

    return <Text {...styles}>{formattedValue}</Text>;
  };

  // Função principal para renderizar o valor com base em diferentes condições
  const renderValue = () => {
    // Renderiza imagens
    if (images && images.includes(property)) {
      return renderImage(value, align);
    }

    // Renderiza tags de status
    if (tags && tags.includes(property)) {
      if (statusPreset) {
        return renderStatus(property, value, index) || (Array.isArray(value) ? isArrayOrArrayOfObjects(value) : null);
      }
      return renderHTML ? (
        <Text dangerouslySetInnerHTML={{ __html: renderHTML }} />
      ) : (
        <ShortenText value={formattedValue} maxLength={maxLengthResume} />
      );
    }

    // Renderiza arrays de subitens
    if (Array.isArray(value)) {
      return isArrayOrArrayOfObjects(value);
    }

    // Renderiza HTML
    if (renderHTML) {
      return formattedValue ?? renderHTMLContent(renderHTML);
    }

    // Renderiza links
    if (linkValue && linkValue !== null) {
      return renderLink(linkValue, formattedValue);
    }

    // Renderiza contadores
    if (category?.count) {
      return rawItem[category.count]?.length || 0;
    }

    // Renderiza labels
    if (type === 'label') {
      return <Text>{formattedValue}</Text>;
    }

    // Formatação com showMore
    if (showMore) {
      return renderFormattedValueWithShowMore(formattedValue);
    }

    // Texto padrão
    return type !== 'text' ? formattedValue : <ShortenText value={formattedValue} maxLength={maxLengthResume} />;
  };

  return <>{renderValue()}</>;
};
