import React from 'react';

import { ImLocation } from 'react-icons/im';

import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';

const TravelTimelineStep = ({ title, progress, infos, stepCount = 0, delay = 0, isBlock = false, setCenter, setZoom }) => {
  function getIcon(info) {
    switch (info?.section) {
      case 'DRAFT':
      case 'CREATED':
      case 'SENT_TO_DRIVER':
      case 'ACCEPTED_BY_DRIVER':
      case 'CTE_TRAVELLING':
      case 'CTE_COMPLETED':
      case 'CANCELLED':
        return info?.section?.toLowerCase();
      case 'low':
        return 'events';
      case 'stop':
        return 'stop';
      case 'emergency':
        return 'halted';
      case 'face':
        return 'facial_recognition';
      case 'no-face':
        return 'no_facial_recognition';
      default:
        if (info.type == 'Coleta') {
          return 'cte_travelling';
        } else if (info.type == 'Descarga/Entrega') {
          return 'cte_completed';
        }

        return 'interrogacao';
    }
  }

  return (
    <Grid
      minW="500px"
      templateColumns="50px 1fr 1fr 30px"
      direction="row"
      w="100%"
      gap={'15px'}
      style={{
        transformOrigin: 'left center',
        animation: 'rlFade 0.5s ease',
        animationFillMode: 'both !important',
        animationDelay: 'var(--delay)',
      }}>
      <Flex alignItems="center" direction="column">
        <Image src={require(`../../../../../assets/svgs/trip/${getIcon(infos)}.svg`)} boxSize="2rem" mt="0.5" mb="0.5" />

        <Box left="12px" top="0" width="2px" height="50px" background="#6C48C280" />
      </Flex>

      <Text color="#0F0A1D5C" textAlign="center">
        {infos?.date ? infos?.date : 'Pendente'}
      </Text>

      <Text fontWeight="bold" color="#0F0A1D">
        {infos?.type}
      </Text>

      {typeof infos?.latitude !== 'undefined' && infos?.latitude != '' && infos?.latitude != 0 && infos?.section !== 'CREATED' && (
        <ImLocation
          title="Origem"
          color="#ea4335"
          size="30px"
          cursor="pointer"
          onClick={(decision) => {
            if (decision) {
              setCenter({
                lat: infos.latitude,
                lng: infos.longitude,
              });
              setZoom(18);
            }
          }}
        />
      )}
    </Grid>
  );
};

export default TravelTimelineStep;
