/**
 *
 * Template	padrão de tabela dinamica do sistema.
 *
 * @param  title titulo do componente (opcional - desejável)
 * @param  subtitle subtitle do componente (opcional)
 * @param  categories categorias da tabela (obrigatório, exemplo: ['NCM', 'Descrição', 'Data'])
 * @param  subCategories subcategorias da tabela (opcional, exemplo: ['NCM', 'Descrição', 'Data'])
 * @param  mainCategoryMobile categoria principal que será exibido apenas no mobile (opcional, exemplo: 'codeReadable')
 * @param  tooltipCategories Aplica tooltips com descrição a categorias específicas.
 * @param  hasCollapsed se a tabela deverá possuir um subnivel (opcional)
 * @param  hasPagination se a tabela haverá paginação (opcional, incluir outras propriedades para o funcionamento)
 * @param  hasDetailsButton se deverá ter um botão nos itens da lista para a pagina de edição/exibição (opcional)
 * @param  hasDetailsButtonSublist se deverá ter um botão nos itens da sublista para a pagina de edição/exibição (opcional)
 * @param  detailsRedirectEndpoint endpoint para o funcionamento de hasDetailsButton (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  detailsRedirectEndpointSublist endpoint para o funcionamento de hasDetailsButtonSublist (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  hasDeleteButton se deverá ter um botão nos itens da lista para a remoção de itens (opcional, incluir deleteButtonAction para o funcionamento)
 * @param  deleteButtonAction necessário para o funcionamento de hasDeleteButton determina a ação do botão (opcional)
 * @param  customButtons gerar lista de botões personalizados (opcional, exemplo: [{ label: 'Editar', icon: <MdOpenInNew size={20} />, action: handleCustomButtonAction }])
 *
 * @param  customFields gerar lista de campos personalizados (opcional, exemplo: [{ type: 'select', options: roleOptions, name: 'role', action: handleCustomUserAction }]
 * type: 'select | radio'
 * name: é o nome da chave que voce deseja receber da API para popular o value dos inputs
 * action: é a ação que será realizada apos o clique no input(retorna dois valores para o action: identifier e currentFilter)
 *
 * options: é a lista de opções para o select(deve conter os valores padrões: label, value, slug) | é apenas para "select"
 * roles: são as regras para determinar se será true ou false um valor da chave escolhida em name, exemplo: { label: 'Ativado', condition: 'Active', status: true } | é apenas para "radio"
 * bgColor: determina a cor global do background ativo(turnIn) e inativo(turnOff) do input radio, exemplo: bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' }
 *
 * @param  collapsedContentType template da sublista, (opcional, tipos: "table" || "timeline")
 * @param  collapsedIcon icone personalizado para o colapso da tabela (opcional)
 * @param  tags necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), exemplo: ['status', 'situacao']
 * @param  statusPreset necessário para o funcionamento das tags (opcional)
 * @param  rawData dados brutos da API (obrigatório para o funcionamento de customFields)
 * @param  list envio da lista a ser exibida (obrigatório)
 * @param  subList renderizar a lista secundária (opcional)
 * @param  pathSublist indica qual será o caminho para puxar a lista de exibição da subList (opcional), exemplo: 'notes' = data.notes
 * @param  emptyListMessage exibe uma mensagem personalizada para a lista vazia.
 * @param  maxLengthResume Limita a quantidade de caracteres para permitir a inclusão do resumo no texto.
 * @param  cellRowSize determina a largura das cedulas de cada coluna(cabeçalho/conteudo) com a mesma proporção escolhida.
 * @param  action ação da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  sorting ordenação da lista pelo cabeçalho | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  metadata metadados da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  setAction atualização da ação | necessário para o funcionamento de hasPagination (opcional)
 * @param  setSorting atualização da ordenação | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  setMetadata atualização da paginação | necessário para o funcionamento de hasPagination (opcional)
 *
 */

//setup
export const commonListConfig = {
  title: 'Listagem de NCMs',
  // Estrutura da tabela
  categories: [
    {
      title: 'NCM',
      value: 'codeReadable',
      type: 'label',
      align: 'center',
    },
    {
      title: 'Descrição',
      value: 'description',
    },
    {
      title: 'Ex tárifarios',
      value: 'exTariffs',
      align: 'center',
    },
    {
      title: 'Ex tipis',
      value: 'exTipis',
      align: 'center',
    },
    {
      title: 'Data de inicio da vigência',
      value: 'startsAtFormatted',
      align: 'center',
    },
    {
      title: 'Status',
      value: 'statusReadable',
      align: 'center',
      variantTag: 'softDashed',
    },
    {
      title: 'Ações',
      defaultValue: '',
      align: 'center',
    },
  ],
  hiddenCategories: ['identifier', 'code', 'startsAt', 'status', 'role'],
  mainCategoryMobile: 'codeReadable',
  // Funcionalidades
  hasCollapsed: false,
  hasPagination: true,
  hasDetailsButton: true,
  detailsRedirectEndpoint: '/vendemmia/ncm-management/detail/${identifier}',
  tags: ['statusReadable'],
  statusPreset: [
    // ... definições de cores para tags
    { color: '#2ECC71', label: 'Ativado', value: 'Ativo' },
    { color: '#E74C3C', label: 'Inativo', value: 'Inativo' },
  ],
  pathSublist: 'itens',
  emptyListMessage: 'Nenhum dado encontrado para os parâmetros filtrados',
  maxLengthResume: 150,
  // Estilo
  resizeRows: '150px 700px 180px 180px 210px 180px 1fr',
  /* order: ['codeReadable', 'description', 'startsAtFormatted', 'statusReadable'], */
};
