import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Box, Flex, Grid, Image } from '@chakra-ui/react';

import logo from '../../../assets/images/logo.svg';
import useResponsiveCheck from '../../../hooks/useResponsiveCheck';
import requests from '../../../services/requests';

import DriverInfo from './components/DriverInfo';
import MapTrajectory from './components/Map/MapTrajectory';
import TripStatus from './components/TripStatus';

const TravelDetailPublicPage = () => {
  const { identifier, identifierLink } = useParams();
  const { viewport } = useResponsiveCheck();

  const [isLoadEntityLink, setIsLoadingEntityLink] = useState(false);
  const [entityLink, setEntityLink] = useState([]);

  const [tripPercentage, setTripPercentage] = useState();
  const [currentDriverPosition, setCurrentDriverPosition] = useState();

  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);

  const [center, setCenter] = useState({
    lat: -23.5792551,
    lng: -46.641581,
  });
  const [zoom, setZoom] = useState(5);

  const loadTravelLink = () => {
    setIsLoadingEntityLink(true);

    requests
      .showTripLink(identifier, identifierLink)
      .then((res) => setEntityLink(res))
      .catch(() => {
        window.location.href = '/public/tms/trip/invalid/link/invalid/share';
      })
      .finally(() => setIsLoadingEntityLink(false));
  };

  const getCurrentDriverPosition = () => {
    requests
      .getCurrentDriverPosition(identifier)
      .then((res) => setCurrentDriverPosition(res))
      .catch(() => {})
      .finally(() => {});
  };

  const fetchPercentageCompletion = async () => {
    try {
      const response = await requests.percentageCompletionDriverFreight(identifier);
      setTripPercentage(response);
    } catch (error) {
      console.error('Erro ao buscar o percentual de conclusão:', error);
    }
  };

  useEffect(() => {
    fetchPercentageCompletion();
    loadTravelLink();
    getCurrentDriverPosition();
  }, []);

  return (
    <>
      <Box as="header" position="fixed" top="0" zIndex="1399" left="0" right="0" height="63px">
        <Flex w="full" h="full" bgColor="primary" align="center" justify="center">
          <Image src={logo} alt="logo vendemmia" />
        </Flex>
      </Box>

      {viewport > '1450' ? (
        <Grid h="calc(100vh - 20px)" mx="20px" gap="10px" pt="83px" templateColumns="700px 1fr">
          <Flex gap="10px" direction="column" textColor="#0F0A1D">
            <DriverInfo entity={entityLink} viewport={viewport} />

            <TripStatus entity={entityLink} setCenter={setCenter} setZoom={setZoom} tripPercentage={tripPercentage} />
          </Flex>

          {!isLoadEntityLink && entityLink && (
            <MapTrajectory
              type="public"
              w="full"
              viewport={viewport}
              isLoadingEntity={isLoadEntityLink}
              entity={entityLink}
              currentDriverPosition={currentDriverPosition}
              isLoadingEvents={isLoadingEvents}
              events={events}
              setCenter={setCenter}
              center={center}
              setZoom={setZoom}
              zoom={zoom}
              checkpoints={[]}
            />
          )}
        </Grid>
      ) : (
        <Flex direction="column" mx="20px" pt="83px" pb="20px" gap="10px">
          {!isLoadEntityLink && entityLink && (
            <MapTrajectory
              h="500px"
              type="public"
              viewport={viewport}
              isLoadingEntity={isLoadEntityLink}
              entity={entityLink}
              currentDriverPosition={currentDriverPosition}
              isLoadingEvents={isLoadingEvents}
              events={events}
              setCenter={setCenter}
              center={center}
              setZoom={setZoom}
              zoom={zoom}
              checkpoints={[]}
            />
          )}

          <Flex gap="10px" direction="column" textColor="#0F0A1D">
            <DriverInfo entity={entityLink} viewport={viewport} />

            <TripStatus entity={entityLink} setCenter={setCenter} setZoom={setZoom} tripPercentage={tripPercentage} />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default TravelDetailPublicPage;
