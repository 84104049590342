import { Grid } from '@chakra-ui/react';

import { renderField } from './renderField';

export const renderContactFields = (contactIndex) => (
  <Grid key={contactIndex} gridTemplateColumns="1fr 1fr" columnGap="40px">
    {renderField(`contacts[${contactIndex}].name`, 'Nome')}
    {renderField(`contacts[${contactIndex}].email`, 'E-mail')}
  </Grid>
);
