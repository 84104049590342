/**
 *
 * Template	padrão de tabela dinamica do sistema.
 *
 * @param  {String} title titulo do componente (opcional - desejável)
 * @param  {String} subtitle subtitle do componente (opcional)
 * @param  {Object[]} categories categorias da tabela e campos a serem populados, 
 * os campos podem ser ordenados pela ordem desejada(só mudar a posição no objeto), somente os campos escolhidos seram exibidos no processo.
 * 
    * @param {categories} title: é o nome da coluna que será exibido na lista, se o campo for vazio(title: '') ele envia o dado de forma oculta.
    * @param {categories} field: é o valor que ativa o filtro da categoria escolhida, exemplo: e.code(o 'e' é padrão para a filtragem)
    * @param {categories} value: deve se usar o valor do campo que vem da API para vincular a categoria criada
    * @param {categories} type: determina alguns estilos diferentes de exibição para aquela chave, exemplo: (label = estilo tag)
    * @param {categories} defaultValue: é o valor padrão de exibição quando a API retorna null/undefined, será exibido o que for adicionado como string, 
    * tambem pode ser usado com valor vazio para casos como o do "Ações" não exibe nenhuma informação na coluna.
    * @param {categories} link: adicionamos link ao campo desejado, o endereço será o endpoint escolhido(pode conter identifier), exemplo: '/comex/process/detail/${identifier}' Caso o identifier da lista seja com outro nome pode ser alterado com identifierKey
    * @param {categories} align: determina o alinhamento do item especifico da tabela.
    * @param {categories} tooltip: Aplica tooltip com descrição na categoria específica. 
    * @param {categories} count: Aplica a contagem de itens de uma categoria específica. 
    * @param {categories} renderHTML: Renderiza HTML como valor da categoria específica.
    * @param {categories} variantTag: aplica estilização pre-definida na statusPreset(softDashed, padrão: solidFlat)
    * @param {Object[]} group: Aplica a marcação dos grupos da tabela.
    * 
      * @param  {group} name titulo do grupo, geralmente será utilizado como primeiro item do grupo (opcional - desejável)
      * @param  {group} color utilizado para marcar o item do grupo com a cor correspondente ao name (opcional)
 * 
 * @param  {Object[]} subCategories subcategorias da tabela, utiliza os mesmos conceitos de categories.
 * @param  {String} mainCategoryMobile categoria principal que será exibido apenas no mobile (opcional, exemplo: 'codeReadable')
 * exemplo: [{ category: 'NCM', describe: 'identificador de NCM' }]
 * @param  {Bool} hasCollapsed se a tabela deverá possuir um subnivel (opcional)
 * @param  {Bool} hasPagination se a tabela haverá paginação (opcional, incluir outras propriedades para o funcionamento)
 * @param  {Bool} hasDetailsButton se deverá ter um botão nos itens da lista para a pagina de edição/exibição (opcional)
 * @param  {Bool} hasDetailsButtonSublist se deverá ter um botão nos itens da sublista para a pagina de edição/exibição (opcional)
 * @param  {String} detailsRedirectEndpoint endpoint para o funcionamento de hasDetailsButton (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  {String} detailsRedirectEndpointSublist endpoint para o funcionamento de hasDetailsButtonSublist (opcional, exemplo: '/vendemmia/ncm-management/detail/${identifier}')
 * @param  {Bool} hasDeleteButton se deverá ter um botão nos itens da lista para a remoção de itens (opcional, incluir deleteButtonAction para o funcionamento)
 * @param  {Function} deleteButtonAction necessário para o funcionamento de hasDeleteButton determina a ação do botão (opcional)
 * @param  {Object[]} customButtons gerar lista de botões personalizados (opcional, 
 * exemplo: [{ label: 'Editar', icon: <MdOpenInNew size={20} />, action: handleCustomButtonAction }])
 *
 *    @param {customButtons | Object[]} main: utilizado para adicionar os customButtons no primeiro nivel da lista, (exemplo de uso: customButtons[0].main)
 *    @param {customButtons | Object[]} collapsed: utilizado para adicionar os customButtons no segundo nivel da lista, (exemplo de uso: customButtons[0].collapsed)
 
 *    @param {customButtons/String} label: nome do botão personalizado a ser exibido.
 *    @param {customButtons/String} tooltip: descrição do tipo tooltip do botão personalizado.
 *    @param {customButtons/IconType} icon: icone de exibição do botão personalizado, tambem é possivel utilizar apenas a label sem icone ou os dois.
 *    @param {customButtons/Function|Bool} isButtonVisible: metodo que executara alguam regra de exibição/ocultação, e será aplicado a um item da lista, exemplo: (item) => item.status === 'PUBLISHED' || item.status === 'DEPRECATED'
 *    @param {customButtons/Function|Bool} isButtonDisabled: metodo que executara alguam regra de habilitar/desabilitar, e será aplicado a um item da lista, exemplo: (item) => !!(hasManagementPermission && item.status !== 'RASCUNHO')
 *    @param {customButtons/Function} action: ação a ser realizada no clique do botão personalizado.
 * 
 * @param  {Object[]} customFields gerar lista de campos personalizados (opcional, 
 * 
    * @param {customFields/String} customFieldstype: 'select | radio'
    * @param {customFields/String} name: é o nome da chave que voce deseja receber da API para popular o value dos inputs
    * @param {customFields/Function} action: é a ação que será realizada apos o clique no input(retorna dois valores para o action: identifier e currentFilter)
    * para customButtons de subCategories é possivel receber na action: identifier e subCategoriesProps
    *
    * @param {customFields} options: é a lista de opções para o select(deve conter os valores padrões: label, value, slug) | é apenas para "select"
    * @param {customFields} roles: são as regras para determinar se será true ou false um valor da chave escolhida em name, exemplo: { label: 'Ativado', condition: 'Active', status: true } | é apenas para "radio"
    * @param {customFields} bgColor: determina a cor global do background ativo(turnIn) e inativo(turnOff) do input radio, exemplo: bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' }
 
* exemplo: [{ type: 'select', options: roleOptions, name: 'role', action: handleCustomUserAction }],
    {
      type: 'radio',
      title: 'Status',
      name: 'statusReadable',
      roles: [
        { label: 'Ativado', condition: 'Ativo', status: true },
        { label: 'Desativado', condition: 'Inativo', status: false },
      ],
      bgColor: { turnIn: '#6BDD9E', turnOff: '#E74C3C' },
      action: handleCustomUserAction,
    },
 * 
 *
 * @param  {String} collapsedContentType template da sublista, (opcional, tipos: "table" || "timeline")
 * @param  {Icon} collapsedIcon icone personalizado para o colapso da tabela (opcional)
 * @param  {Array} tags necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), 
 * exemplo: ['status', 'situacao']
 * @param  {Object[]} statusPreset necessário para o funcionamento das tags (opcional), 
 * exemplo: statusPreset: [{
    // ... definições de cores para tags
     @param {statusPreset/String} color: define a cor da tag 
     @param {statusPreset/String} label: define o nome que sera exibido na tag, pode ser qualquer nome
     @param {statusPreset/String} value: deve ser definido o valor que vem do campo da API, precisa ser exatamente igual para o funcionamento
    },
  ]
 * @param  {Array} images necessário para o funcionamento das cores de status, define quais valores receberam a estilização (opcional), 
 * exemplo: ['originModality']
 * @param  {Object[]} imagePreset necessário para o funcionamento das images (opcional), 
 * exemplo: imagePreset: [{
    // ... definições de cores para tags
     @param {imagePreset/String} image: define o endereço da imagem a ser exibido no lugar do texto
     @param {imagePreset/String} value: deve ser definido o valor que vem do campo da API, precisa ser exatamente igual para o funcionamento
    },
  ]
 * @param  {Object[]} rawData dados brutos da API (obrigatório para o funcionamento de customFields)
 * @param  {Object[]} list envio da lista a ser exibida (obrigatório)
 * @param  {Object[]} subList renderizar a lista secundária (opcional)
 * @param  {String} identifierKey adiciona a key personalizada da lista, caso fuja do padrão 'identifier' ou 'processIdentifier'
 * @param  {String} pathSublist indica qual será o caminho para puxar a lista de exibição da subList (opcional), exemplo: 'notes' = data.notes
 * @param  {String} emptyListMessage exibe uma mensagem personalizada para a lista vazia.
 * @param  {Number} maxLengthResume Limita a quantidade de caracteres para permitir a inclusão do resumo no texto.
 * @param  {Number} maxLengthResumeChildren Limita a quantidade de caracteres para permitir a inclusão do resumo no texto do filho(subList).
 * @param  {String} cellRowSize determina a largura das cedulas de cada coluna(cabeçalho/conteudo) com a mesma proporção escolhida.
 * @param  {String} resizeRows determina a largura das cedulas de cada coluna de forma personalizada para o primeiro nivel da lista.
 * @param  {String} resizeRowsChildren determina a largura das cedulas de cada coluna de forma personalizada para o segundo nivel da lista(colapso).
 *
 * @param  {State} action ação da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {State} sorting ordenação da lista pelo cabeçalho | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  {State} metadata metadados da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {SetState} setAction atualização da ação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {SetState} setSorting atualização da ordenação | necessário para o funcionamento de filtros em categories.field (opcional)
 * @param  {SetState} setMetadata atualização da paginação | necessário para o funcionamento de hasPagination (opcional)
 * @param  {Function} handleSort metodo que determina a atualização da paginação com o filtro escolhido | necessário para o funcionamento de hasPagination (opcional)
 *
 */

import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';

import Paginate from '../Paginate/Paginate';

import CommonListCategories from './CommonListCategories';
import CommonListItemContent from './CommonListItemContent';
import CommonListStyles from './CommonListStyles';
import { SkeletonContent } from './components/SkeletonContent';
import { useCommonListProps } from './Hooks/useCommonListProps';

const CommonList = ({ children, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    list,
    categories,
    subCategories,
    data,
    isMobile,
    deviceType,
    viewport,
    mainCategoryMobile,
    setMetadata,
    setAction,
    hasDetailsButton,
    detailsRedirectEndpoint,
    hasDetailsButtonSublist,
    detailsRedirectEndpointSublist,
    hasDeleteButton,
    deleteButtonAction,
    deleteButtonSubtitle,
    customButtons,
    customFields,
    radioBgColor,
    action,
    metadata,
    hasPagination,
    hasPages,
    hasCollapsed,
    collapsedContentType,
    hiddenCategories,
    tooltipCategories,
    tags,
    statusPreset,
    images,
    imagePreset,
    order,
    orderChildren,
    sorting,
    handleSort,
    title,
    subtitle,
    subcategories,
    subList,
    pathSublist,
    isLoading,
    identifier,
    formattedList,
    emptyListMessage,
    maxLengthResume,
    maxLengthResumeChildren,
    cellRowSize,
    resizeRows,
    resizeRowsChildren,
    gridTemplateLength,
  } = useCommonListProps({ ...props, children });

  // Atualiza a lista conforme realiza uma nova chamada a API
  useEffect(() => {
    if (list) {
      if (!isLoading && list?.length > 0) {
        setIsLoaded(true);
      } else {
        list?.length > 0 ? setIsLoaded(false) : setIsLoaded(true);
      }
    }
  }, [isLoading, list]);

  return (
    <Card m="10px" border={'1px solid #70707036'}>
      <Box overflow={'auto'}>
        {title && <Heading>{title}</Heading>}
        {subtitle && <Text as={'h3'}>{subtitle}</Text>}
        <CommonListStyles radioBgColor={radioBgColor} viewport={viewport} cellRowSize={cellRowSize} />
        <Flex m={'15px'} flexDirection={'column'} gap={'20px'} width={{ md: 'max-content' }} minW={{ md: 'calc(100% - 30px)' }}>
          <CommonListCategories
            categories={categories}
            tooltipCategories={tooltipCategories}
            gridTemplateLength={resizeRows ? resizeRows : gridTemplateLength}
            cellRowSize={cellRowSize}
            isMobile={isMobile}
            sorting={sorting}
            handleSort={handleSort}
            isLoading={isLoading}
          />
          <List display={'flex'} flexDirection={'column'} gap={'10px'}>
            {/* carregamento do skeleton */}
            {!isLoaded ? (
              <SkeletonContent />
            ) : isMobile ? (
              <Accordion display={'flex'} gap={'15px'} flexDirection={'column'} isMobile={isMobile}>
                {formattedList?.map((item, key) => (
                  <AccordionItem
                    key={key}
                    border={'1px solid #70707036'}
                    borderRadius={'10px'}
                    columnGap={'15px'}
                    p={'0 10px'}
                    lineHeight={'40px'}
                    boxShadow="sm">
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {mainCategoryMobile === (null || '' || undefined)
                          ? Object.entries(item)[0][1]
                          : item.hasOwnProperty(mainCategoryMobile) && item[mainCategoryMobile] !== ''
                          ? item[mainCategoryMobile]
                          : '-'}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel padding={'10px 0'}>
                      <CommonListItemContent
                        list={formattedList}
                        hasCollapsed={hasCollapsed}
                        collapsedContentType={collapsedContentType}
                        rawItem={list[key]}
                        item={item}
                        content={children}
                        gridTemplateLength={resizeRows ? resizeRows : gridTemplateLength}
                        subList={subList}
                        subcategories={subcategories}
                        identifier={identifier[key]}
                        hasDetailsButton={hasDetailsButton}
                        hasDetailsButtonSublist={hasDetailsButtonSublist}
                        detailsRedirectEndpoint={detailsRedirectEndpoint}
                        detailsRedirectEndpointSublist={detailsRedirectEndpointSublist}
                        hasDeleteButton={hasDeleteButton}
                        deleteButtonAction={deleteButtonAction}
                        deleteButtonSubtitle={deleteButtonSubtitle}
                        customButtons={customButtons}
                        customFields={customFields}
                        isMobile={isMobile}
                        deviceType={deviceType}
                        viewport={viewport}
                        categories={categories}
                        maxLengthResume={maxLengthResume}
                        maxLengthResumeChildren={maxLengthResumeChildren}
                        emptyListMessage={emptyListMessage}
                        pathSublist={pathSublist}
                        tooltipCategories={tooltipCategories}
                        cellRowSize={cellRowSize}
                        listIndex={key}
                        tags={tags}
                        statusPreset={statusPreset}
                        images={images}
                        imagePreset={imagePreset}
                        data={data}
                        order={order}
                        orderChildren={orderChildren}
                        isLoading={isLoading}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            ) : formattedList && formattedList.length > 0 ? (
              formattedList.map((item, key) => (
                <ListItem key={key}>
                  <CommonListItemContent
                    list={formattedList}
                    hasCollapsed={hasCollapsed}
                    collapsedContentType={collapsedContentType}
                    item={item}
                    rawItem={list[key]}
                    content={children}
                    gridTemplateLength={resizeRows ? resizeRows : gridTemplateLength}
                    subList={subList}
                    subcategories={subcategories}
                    identifier={identifier[key]}
                    hasDetailsButton={hasDetailsButton}
                    hasDetailsButtonSublist={hasDetailsButtonSublist}
                    detailsRedirectEndpoint={detailsRedirectEndpoint}
                    detailsRedirectEndpointSublist={detailsRedirectEndpointSublist}
                    hasDeleteButton={hasDeleteButton}
                    deleteButtonAction={deleteButtonAction}
                    deleteButtonSubtitle={deleteButtonSubtitle}
                    customButtons={customButtons}
                    customFields={customFields}
                    isMobile={isMobile}
                    deviceType={deviceType}
                    viewport={viewport}
                    categories={categories}
                    maxLengthResume={maxLengthResume}
                    maxLengthResumeChildren={maxLengthResumeChildren}
                    tooltipCategories={tooltipCategories}
                    pathSublist={pathSublist}
                    emptyListMessage={emptyListMessage}
                    cellRowSize={cellRowSize}
                    resizeRowsChildren={resizeRowsChildren}
                    listIndex={key}
                    tags={tags}
                    statusPreset={statusPreset}
                    images={images}
                    imagePreset={imagePreset}
                    data={data}
                    order={order}
                    orderChildren={orderChildren}
                    isLoading={isLoading}
                  />
                </ListItem>
              ))
            ) : (
              <Text alignSelf={'center'}>{emptyListMessage}</Text>
            )}
          </List>
        </Flex>
      </Box>
      {hasPagination && hasPages && (
        <Paginate metadata={metadata} setMetadata={setMetadata} action={action} setAction={setAction} showDetails={true} />
      )}
    </Card>
  );
};

export default CommonList;
