import React from 'react';

import { useFormikContext } from 'formik';

import { Button } from '@chakra-ui/react';

export const CustomSubmitButton = ({ name = 'Enviar', loadingText = 'Enviando', isLoading = false }) => {
  const { submitForm } = useFormikContext();

  // Verifica se `submitForm` está disponível
  if (!submitForm) {
    console.error('Formik context is not available.');
    return null;
  }

  return (
    <Button
      variant={'submit'}
      type="button" // Não é um botão de submit
      onClick={() => submitForm()} // Aciona a submissão do formulário
      isLoading={isLoading}
      loadingText={loadingText}>
      {name}
    </Button>
  );
};
