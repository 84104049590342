import React, { useRef, useState } from 'react';

import { MdOutlineModeEditOutline } from 'react-icons/md';

import { useDisclosure } from '@chakra-ui/react';

import CommonList from '../../../components/CommonList/CommonList';
import { commonListMethods } from '../../../components/CommonList/methods/commonListMethods';
import { CommonModal } from '../../../components/CommonModal/CommonModal';
import Page from '../../../components/Page';
import { useForceRefresh } from '../../../hooks/useForceRefresh';
import permissions from '../../../services/permissions';
import requests from '../../../services/requests';
import { executeRequest } from '../../../utils/requests/executeRequest';

import { commonListConfig } from './components/commonListConfig';
import { DynamicForm } from './components/DynamicForm';
import ModalNewFaq from './components/ModalNewFaq';

const FaqPage = () => {
  const hasPermission = permissions.vendemmiaManagementFaqManagement;
  const request = useRef(0);

  //paginate commonList
  const { action, setAction, handleSort, sorting, metadata, setMetadata, isLoading, setIsLoading } = commonListMethods();

  //modals methods
  const { isOpen: modalEditIsOpen, onOpen: modalEditOnOpen, onClose: modalEditOnClose } = useDisclosure();

  //fields
  const [identifier, setIdentifier] = useState();

  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);

  const load = (filters, key, page) => {
    getListFaqs(filters);
  };

  // Custom hook for refresh
  const { forceLoadTrigger, triggerRefresh } = useForceRefresh(load);

  const addTopicToList = () => {
    load();
  };

  const getListFaqs = async (filters) => {
    const res = await executeRequest({
      action: () => requests.listFaqs(filters),
      setIsLoading,
    });

    setList(res.data);
    setMetadata(res.meta);
  };

  const handleDelete = async (identifier) => {
    await executeRequest({
      action: () => requests.deleteFaq(identifier),
      msgSuccess: 'Tópico excluído com sucesso!',
      msgError: 'Erro ao excluído o tópico, tente novamente!',
      setIsLoading,
      triggerRefresh,
    });
  };

  //edit
  const handleOpenModalEdit = (id, data) => {
    setIdentifier(id);
    updateInitialValues(data);
    modalEditOnOpen();
  };

  const [initialValues, setInitialValues] = useState(null);

  const updateInitialValues = (data) => {
    const updatedValues = {
      title: data.title || '',
      description: data.description || '',
      typeSystem: data.typeSystem || '',
      modality: data.modality || '',
      videoUrl: null,
      status: 'DRAFT',
    };
    setInitialValues(updatedValues);
  };

  const onSubmitForm = async (values) => {
    await executeRequest({
      action: () => requests.editFaq(identifier, values),
      msgSuccess: 'Tópico editado com sucesso',
      msgError: 'Erro ao editar, tente novamente!',
      setIsLoading,
      triggerRefresh,
    });
  };

  //component preset
  const subtitle = `Existe um total de ${metadata.total_count} perguntas frequentes`;

  // Component preset
  const customButtons = [
    {
      main: [
        { label: 'Editar', tooltip: 'Editar protesto', icon: <MdOutlineModeEditOutline size={20} />, action: handleOpenModalEdit },
      ],
      collapsed: [],
    },
  ];

  return (
    <Page
      screen="faq"
      title="Perguntas frequentes"
      breadcrumbs={[{ link: '#', title: 'Gestão' }]}
      textFilterPlaceholder="Buscar por título ou descrição"
      setIsMobile={setIsMobile}
      hasPermission={hasPermission}
      list={list}
      metadata={metadata}
      load={load}
      isContentLoading={isLoading}
      isRefreshLoading={isLoading}
      allowEmptySearchPeriod={true}
      showPeriodFilter={false}
      showFilters={false}
      forceLoadTrigger={forceLoadTrigger}
      FAB={[
        {
          title: 'Cadastrar Perguntas',
          text: 'Cadastro de Perguntas',
          action: <ModalNewFaq addTopicToList={addTopicToList} />,
          modality: 'custom',
        },
      ]}>
      <CommonList
        subtitle={subtitle}
        //lists
        list={list}
        rawData={list}
        //actions
        action={action}
        sorting={sorting}
        metadata={metadata}
        setAction={setAction}
        setMetadata={setMetadata}
        handleSort={handleSort}
        deleteButtonAction={handleDelete}
        //custom
        customButtons={customButtons}
        //loading
        isLoading={isLoading}
        //props
        {...commonListConfig}
      />

      {/* edit */}
      <CommonModal
        heading={'Edição do Tópico'}
        initialValues={initialValues}
        isOpen={modalEditIsOpen}
        onOpen={modalEditOnOpen}
        onClose={modalEditOnClose}
        size={'3xl'}
        removeRadius={true}
        hasForm={true}
        callback={onSubmitForm}
        //loading
        isLoading={isLoading}
        loadingText={'Salvando'}>
        <DynamicForm />
      </CommonModal>
    </Page>
  );
};

export default FaqPage;
