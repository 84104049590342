import { Field } from 'formik';

import { Flex, FormLabel, Input } from '@chakra-ui/react';

export const renderField = (name, placeholder, props) => (
  <Flex direction="column" mb={4}>
    <FormLabel>{placeholder}</FormLabel>
    <Field name={name}>{({ field }) => <Input {...field} placeholder={placeholder} {...props} />}</Field>
  </Flex>
);
